import { PrivilegedRouteMap } from '@thoughtspot/blink-context';
import React, { lazy } from 'react';
import { BlinkV2MetricsContextStates } from '/@services/system/blink-v2-metrics-context-states';
import { PrivilegeList } from './privilege-list';

const SetupHubPage = lazy(() => import('../pages/setup-hub/setup-hub-page'));

const EmbedTestPage = lazy(() =>
    import('../pages/embed-test-page/embed-test-page'),
);

const EmbraceOauthRedirectionPage = lazy(() =>
    import('../pages/embrace-oauth/embrace-oauth-redirection'),
);

const UserPreferencePage = lazy(() =>
    import('../pages/user-preference/user-preference-page'),
);

const OnboardingPage = lazy(() => import('../pages/onboarding/onboarding'));

const SeekwellAuthRedirectionPage = lazy(() =>
    import('../pages/seekwell-auth-redirect/seekwell-auth-complete'),
);

const DeeplinkPage = lazy(() => import('../pages/deeplink-page/deeplink-page'));

const LoadingPage = lazy(() => import('../pages/loader/loader-page'));

const ImportTmlPage = lazy(() => import('../pages/tml/import-tml-page'));

const EditTmlPage = lazy(() => import('../pages/tml/edit-tml-page'));

const ComsConnectPage = lazy(() => import('../pages/coms/coms-connect-page'));

const LoginPage = lazy(() => import('../pages/login/login-page'));

const ResetPasswordPage = lazy(() =>
    import('../components/reset-password/reset-password.container'),
);

const RequestResetPasswordPage = lazy(() =>
    import('../components/forgot-password/forgot-password.container'),
);

const AccountActivationV2Container = lazy(() =>
    import('../components/account-activation/account-activation-v2.container'),
);

const EdgePage = React.lazy(() => import('../pages/edge/edge-page'));

const NewOnboardingPage = React.lazy(() =>
    import('../pages/new-onboarding/newOnboarding-page'),
);

const PrintView = lazy(() =>
    import('../components/print/print-view.container'),
);

const StatusPage = React.lazy(() => import('../pages/status/status-page'));

const unauthenticatedRoutes: PrivilegedRouteMap = {
    Login: {
        path: '/login',
        element: <LoginPage />,
        privileges: [PrivilegeList.MustNotBeAuthenticated],
        pageContext: BlinkV2MetricsContextStates.LOGIN_PAGE,
    },
    ForgotPassword: {
        path: '/requestresetpassword',
        element: <RequestResetPasswordPage />,
        privileges: [PrivilegeList.MustNotBeAuthenticated],
        pageContext: BlinkV2MetricsContextStates.FORGOT_PASSWORD,
    },
    ResetPassword: {
        path: '/resetpassword',
        element: <ResetPasswordPage />,
        privileges: [PrivilegeList.MustNotBeAuthenticated],
        pageContext: BlinkV2MetricsContextStates.RESET_PASSWORD,
    },
    Deeplink: {
        path: '/deeplink',
        element: <DeeplinkPage />,
        privileges: [PrivilegeList.MustNotBeAuthenticated],
        pageContext: BlinkV2MetricsContextStates.DEEPLINK_PAGE,
    },
    AccountActivation: {
        path: '/activate',
        element: <AccountActivationV2Container />,
        pageContext: BlinkV2MetricsContextStates.ACCOUNT_ACTIVATION,
    },
    Edge: {
        path: '/edge',
        element: <EdgePage />,
        pageContext: BlinkV2MetricsContextStates.EDGE,
    },
    PrintViewV2: {
        path: '/print-v2',
        element: <PrintView />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.PRINT_PAGE,
    },
    Loader: {
        path: '/loader',
        element: <LoadingPage />,
        privileges: [],
        pageContext: BlinkV2MetricsContextStates.ANALYST_STUDIO,
    },
};

export const AUTHENTICATED_CORE_ROUTES: PrivilegedRouteMap<null> = {
    SetupHub: {
        path: '/setup',
        element: <SetupHubPage />,
        pageContext: BlinkV2MetricsContextStates.SETUP,
    },
    ComsConnector: {
        path: '/coms-connect/:connection',
        element: <ComsConnectPage />,
        pageContext: BlinkV2MetricsContextStates.COMS_CONNECT_PAGE,
    },
    EmbraceOauth: {
        path: '/embrace/oauth',
        element: <EmbraceOauthRedirectionPage />,
        pageContext: BlinkV2MetricsContextStates.OAUTH_REDIRECT,
    },
    UserPreferencePage: {
        path: '/user-preference',
        element: <UserPreferencePage />,
        pageContext: BlinkV2MetricsContextStates.USER_PREFERENCES,
        title: 'MY_PROFILE',
    },
    SeekwellAuthRedirect: {
        path: '/seekwell-destination-auth-redirect',
        element: <SeekwellAuthRedirectionPage />,
        pageContext: BlinkV2MetricsContextStates.SEEKWELL_AUTH_REDIRECT,
    },
    Onboarding: {
        path: '/onboarding',
        element: <OnboardingPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.ONBOARDING,
    },
    ImportTml: {
        path: '/import-tsl/:metadataType/:metadataGuid',
        element: <ImportTmlPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.IMPORT_TSL,
    },
    ImportTsl: {
        path: '/import-tsl/:importType',
        element: <ImportTmlPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.IMPORT_TSL,
    },
    TmlEditor: {
        path: '/tsl-editor/:metadataType/:metadataGuid',
        element: <EditTmlPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.TSL_EDITOR,
    },
    EmbedTestPage: {
        path: '/embed-test-page',
        element: <EmbedTestPage />,
        pageContext: BlinkV2MetricsContextStates.EMBED_TEST_PAGE,
    },
    NewOnboarding: {
        path: '/welcome/*',
        element: <NewOnboardingPage />,
        pageContext: BlinkV2MetricsContextStates.NEWONBOARDING,
    },
    Status: {
        path: '/status',
        element: <StatusPage />,
        disableTopNav: true,
        pageContext: BlinkV2MetricsContextStates.STATUS,
    },
};

export function getCurrentPathByLocationHash(): string {
    let currentPath = window.location.hash;
    if (currentPath.startsWith('#')) {
        currentPath = currentPath.slice(1);
    }
    return currentPath;
}

export const UNAUTHENTICATED_ROUTE_OBJECTS = unauthenticatedRoutes;
export const CORE_ROUTES = {
    ...AUTHENTICATED_CORE_ROUTES,
    ...unauthenticatedRoutes,
};
