import _ from 'lodash';
import { Subject } from 'rxjs';
import {
    ApolloClient,
    ApolloError,
    DocumentNode,
    FetchResult,
    gql,
    InternalRefetchQueriesInclude,
    MutationFunctionOptions,
    MutationHookOptions,
    MutationResult,
    OperationVariables,
} from '/@utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    BachSessionId,
    DataPaginationParamsInput,
    LoadingState,
} from '../generated/graphql-types';

export interface BachMutationHookOptions<TD, TV>
    extends MutationHookOptions<TD, TV> {
    setNextGenNoShouldReplaceCurrent?: boolean;
    setVisualizationsLoading?: boolean;
    setPhrasesLoading?: boolean;
    dataPaginationParams?: DataPaginationParamsInput;
    shouldRefetchData?: boolean;
    customRefetchQueriesFn?: (
        client: ApolloClient<any>,
        result: FetchResult<TD>,
    ) => InternalRefetchQueriesInclude;
    awaitForQueries?: boolean;
    setPinboardLoading?: boolean;
    trackPinboardMutationProgress?: boolean;
    waitUntilMutationIsInFlight?: boolean;
}

export interface BachMutationFunctionOptions<TD, TV>
    extends MutationFunctionOptions<TD, TV> {
    setNextGenNoShouldReplaceCurrent?: boolean;
    setVisualizationsLoading?: boolean;
    setPhrasesLoading?: boolean;
    setPinboardLoading?: boolean;
    trackPinboardMutationProgress?: boolean;
}

export type BachMutationTuple<TData, TVariables> = [
    (
        options?: BachMutationFunctionOptions<TData, TVariables>,
    ) => Promise<FetchResult<TData>>,
    MutationResult<TData>,
];

export const bachSessionKeyQuery: DocumentNode = gql`
    {
        sessionId
        genNo
    }
`;

export const bachSessionWithAcSessionKeyQuery: DocumentNode = gql`
    {
        sessionId
        genNo
        acSession {
            sessionId
            genNo
        }
    }
`;

export const bachPinboardSessionKeyQuery: DocumentNode = gql`
    {
        sessionId
        genNo
        contextBookSessions {
            contextBookId
            sessionId {
                sessionId
                genNo
            }
        }
    }
`;

export const getStatefulMutationParams = (
    isPinboardSession: boolean,
    includeAcSessionInfo: boolean,
) => {
    if (isPinboardSession) {
        return {
            typeName: 'BachPinboardSession',
            sessionKeyQuery: bachPinboardSessionKeyQuery,
            nestedSessionKeys: ['contextBookSessions'],
        };
    }

    return {
        typeName: 'BachSessionId',
        sessionKeyQuery: includeAcSessionInfo
            ? bachSessionWithAcSessionKeyQuery
            : bachSessionKeyQuery,
        nestedSessionKeys: includeAcSessionInfo ? ['acSession'] : [],
    };
};

export type BachMutationEventData<TData, TVariables> = {
    stage: LoadingState;
    mutationName: string;
    bachSession: BachSessionId;
    data?: TData | TVariables | ApolloError;
};

const bachMutationEventSubject = new Subject();
export function getBachMutationEventSubject<TData, TVariables>() {
    return bachMutationEventSubject as Subject<
        BachMutationEventData<TData, TVariables>
    >;
}

/**
 *
 * This fulfils the requirement to relay external modifications like
 * drilldown / filter changes / display mode changes / undo-redo
 * to the subscribers. While the current answer tokens and data
 * can be obtained from cache but it doesn't give us details on how
 * the answer state was reached (operation and payload for such operations),
 * hence the need for this event.
 */
export function publishBachMutationEvent<TData, TVariables>(
    mutation: DocumentNode,
    stage: LoadingState,
    sessionId: string,
    data?: TData | TVariables | ApolloError,
) {
    /**
     * mutation MyMutation {
     *   Answer__drillDown(id: string) {
     *      success
     *   }
     * }
     *
     * from the above example MyMutation is mutationName
     * and Answer__drillDown is gqlOperationName
     */
    const mutationName = (mutation.definitions[0] as any)?.name?.value;
    const gqlOperationName = (mutation.definitions[0] as any)?.selectionSet
        ?.selections[0]?.name?.value;

    let bachMutationEventData: BachMutationEventData<TData, TVariables>;

    if (stage === LoadingState.InProgress) {
        // This means a mutation was triggered so the request will contain
        // session info of the answer on which the mutation was triggered

        const variables = data;
        const bachSession = (variables as OperationVariables)?.session;

        bachMutationEventData = {
            stage,
            bachSession,
            mutationName,
            data: variables,
        };
    }

    if (stage === LoadingState.Success) {
        // mutation was successful
        const response = data?.[gqlOperationName];
        const bachSession = response?.id || { sessionId };

        bachMutationEventData = {
            stage,
            bachSession,
            mutationName,
            data: response,
        };
    }

    if (stage === LoadingState.Failure) {
        bachMutationEventData = {
            stage,
            bachSession: { sessionId },
            mutationName,
            data: null,
        };
    }

    bachMutationEventSubject.next(bachMutationEventData);
}
