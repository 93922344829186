import { blinkConstants } from '@thoughtspot/blink-constants';
import {
    isCopilotEnabled,
    isSpotterExperienceEnabled,
} from '@thoughtspot/session-service';

export const getSpotterPlanType = () => {
    if (isCopilotEnabled()) {
        return blinkConstants.spotterPlanTypes.standaloneSpotter;
    }
    if (isSpotterExperienceEnabled()) {
        return blinkConstants.spotterPlanTypes.tsaSpotter;
    }
    return blinkConstants.spotterPlanTypes.tsaSpotterDisabled;
};
