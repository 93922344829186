import {
    ApolloError,
    gql,
    LazyQueryHookOptions as LazyQueryHookOptionsOrg,
    MutationHookOptions as MutationHookOptionsOrg,
    OperationVariables,
    QueryHookOptions as QueryHookOptionsOrg,
    TypedDocumentNode,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import {
    ErrorContext,
    ErrorContextType,
    IDefaultErrorCodes,
} from '@thoughtspot/blink-context';
import { create } from '@thoughtspot/logger';
import { DocumentNode } from 'graphql';
import { useCallback, useContext, useEffect, useState } from 'react';
import { containsError, getErrorObjectFromGraphQLErrors } from '../error-utils';
import { useCachedQuery as useQuery } from './useCachedQuery';

const logger = create('Custom-Apollo-Hooks');

/** **********************************************************
 * TYPES EXTENDING APOLLO HOOKS WITH ERROR HANDLING
 *********************************************************** */

/**
 * Wrapper interface for query hook options param
 */
export interface QueryHookOptions<
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
> extends QueryHookOptionsOrg<TData, TVariables> {
    handledErrors?: (TErrorCode | IDefaultErrorCodes)[];
    errorMappingContext?: Record<string, any>;
}

/**
 * Wrapper interface for mutation hook options param
 */
export interface MutationHookOptions<
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
> extends MutationHookOptionsOrg<TData, TVariables> {
    handledErrors?: (TErrorCode | IDefaultErrorCodes)[];
    errorMappingContext?: Record<string, any>;
}

/**
 * Wrapper interface for lazy query hook options param
 */
export interface LazyQueryHookOptions<
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
> extends LazyQueryHookOptionsOrg<TData, TVariables> {
    handledErrors?: (TErrorCode | IDefaultErrorCodes)[];
    errorMappingContext?: Record<string, any>;
}

/** **********************************************************
 * Utility functions
 *********************************************************** */

/**
 * Utility fn to check if error needs to be ignored
 * @param options
 */
const shouldIgnoreError = <
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
>(
    options?: QueryHookOptions<TData, TVariables, TErrorCode>,
    // https://www.apollographql.com/docs/react/data/error-handling/#setting-an-error-policy
    // if options.errorPolicy equals 'ignore' then graphQLErrors are ignored (error.graphQLErrors is not populated),
    // and any returned data is cached and rendered as if no errors occurred.
): boolean => options?.errorPolicy === 'ignore';

/** **********************************************************
 * APOLLO HOOKS WRAPPERS
 *********************************************************** */

const useErrorPropagationHook = (
    handledErrors?: string[],
    query?: DocumentNode | TypedDocumentNode,
    errorMappingContext?: Record<string, any>,
) => {
    const errorCtx = useContext<ErrorContextType<string>>(ErrorContext);

    // We create a copy of the handled errors first time the hook
    // is called, so that for further rerenders of the caller, the
    // reference to the handled errors doesn't change, this is because
    // handledErrors in an array, and since the default react behaviuor is
    // to do shallow compare, the handledErrors would appear to change
    // with every render.
    // If handledErrors need to be changed dynamicaaly we will need to
    // use a deep compare effect here (for eg. useDeepCompareEffect from react-use)
    const [handledErrorsCopy, setHandledErrorsCopy] = useState<string[]>(
        handledErrors,
    );
    const setError = useCallback(
        (err: ApolloError) => {
            if (!err) return;
            const errors = getErrorObjectFromGraphQLErrors<string>(
                err,
                query,
                errorMappingContext,
            );
            if (!errors) return;
            // If the caller can handle the error, the don't propagate the error
            if (containsError(errors, handledErrorsCopy)) return;
            // Else propagate the error
            errorCtx.postError(errors);
        },
        [query, errorMappingContext, handledErrorsCopy, errorCtx.postError],
    );

    return {
        setError,
    };
};

/**
 * Wrapper function for useQuery hook
 * This will pass the error to error context
 * @param query
 * @param options
 */
const useQueryWrapper = <
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
>(
    query: Parameters<typeof useQuery>[0],
    options?: QueryHookOptions<TData, TVariables, TErrorCode>,
) => {
    const { setError } = useErrorPropagationHook(
        options?.handledErrors as string[],
        query,
        options?.errorMappingContext,
    );
    const opts: QueryHookOptions<TData, TVariables> = {
        ...options,
    };
    const isErrorIgnored = shouldIgnoreError(options);
    if (options?.onError) {
        opts.onError = error => {
            if (!isErrorIgnored) {
                logger.warn('Encountered error', error);
                setError(error);
            } else {
                logger.warn('Encountered ignorable error', error);
            }
            return options?.onError?.(error);
        };
    }
    const result = useQuery<any, any>(query, opts);
    useEffect(() => {
        if (result.error && !options?.onError && !isErrorIgnored) {
            setError(result.error);
        }
    }, [result.error, isErrorIgnored]);

    return result;
};

/**
 * Wrapper function for useMutation hook
 * This will pass the error to error context
 * @param query
 * @param options
 */
const useMutationWrapper = <
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
>(
    mutation: Parameters<typeof useMutation>[0],
    options: MutationHookOptions<TData, TVariables, TErrorCode> = {},
) => {
    const { setError } = useErrorPropagationHook(
        options?.handledErrors as string[],
        mutation,
        options?.errorMappingContext,
    );
    const opts: MutationHookOptions<TData, TVariables> = {
        ...options,
    };
    if (options?.onError) {
        opts.onError = error => {
            logger.warn('Encountered error', error);
            setError(error);
            return options?.onError?.(error);
        };
    }

    const result = useMutation<any, any>(mutation, opts);
    if (
        result?.[1]?.error &&
        !options?.onError &&
        options.errorPolicy === 'all'
        // https://github.com/apollographql/apollo-client/issues/6966
        // in case of mutations apollo does not trigger onError calling this setError
        // to make sure we set the error boundaries
    ) {
        logger.warn('Encountered error', result?.[1]?.error);
        setError(result?.[1]?.error);
    }
    return result;
};

/**
 * Wrapper function for useLazyQuery hook
 * This will pass the error to error context
 * @param query
 * @param options
 */
const useLazyQueryWrapper = <
    TData = any,
    TVariables = OperationVariables,
    TErrorCode = any
>(
    query: Parameters<typeof useLazyQuery>[0],
    options?: LazyQueryHookOptions<TData, TVariables, TErrorCode>,
) => {
    const { setError } = useErrorPropagationHook(
        options?.handledErrors as string[],
        query,
        options?.errorMappingContext,
    );
    const opts: QueryHookOptions<TData, TVariables> = {
        ...options,
    };
    if (options?.onError) {
        opts.onError = error => {
            logger.warn('Encountered error', error);
            setError(error);
            return options?.onError?.(error);
        };
    }
    const result = useLazyQuery<any, any>(query, opts);
    if (result?.[1]?.error && !options?.onError) {
        logger.warn('Encountered error', result?.[1]?.error);
        setError(result?.[1]?.error);
    }
    return result;
};

export * from '@apollo/client';
export {
    useQueryWrapper as useQuery,
    useMutationWrapper as useMutation,
    useLazyQueryWrapper as useLazyQuery,
    gql,
};
