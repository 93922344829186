import { useTranslation } from '@thoughtspot/i18n';
import { Vertical } from '@thoughtspot/radiant-react/widgets/layout';
import { Modal, ModalSize } from '@thoughtspot/radiant-react/widgets/modal';
import { SafeHTML } from '@thoughtspot/radiant-react/widgets/safe-html';
import React from 'react';
import styles from './spotter-book-call-modal.module.scss';

interface SpotterBookCallModalProps {
    isModalOpen: boolean;
    onCloseModal: () => void;
}

export const SpotterBookCallModal: React.FC<SpotterBookCallModalProps> = ({
    isModalOpen,
    onCloseModal,
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={isModalOpen}
            confirmButton={{
                onConfirm: () => onCloseModal(),
                text: t('copilot.book.call.modal.button'),
                className: 'calendar-id',
            }}
            dismissButton={{
                text: t('copilot.cancel.btn.msg'),
                onClick: () => onCloseModal(),
            }}
            title={t('copilot.book.call.modal.title')}
            size={ModalSize.XSMALL}
            modalTestId="book-call-modal"
            customFooterClassName={styles.bookCallFooter}
        >
            <Vertical vAlignContent="center" spacing="f">
                <SafeHTML html={t('copilot.book.call.modal.description')} />
            </Vertical>
        </Modal>
    );
};

export default SpotterBookCallModal;
