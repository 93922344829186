import { useTranslation } from '@thoughtspot/i18n';
import _ from 'lodash';
import React from 'react';
import styles from './non-embed.module.scss';

export const NonEmbedAccess: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div data-testid="blocked-message" className={styles.content}>
            {t('NON_EMBED_FULL_APP_ACCESS')}
        </div>
    );
};
export default NonEmbedAccess;
